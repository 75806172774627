import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageTranslateService } from '../../core/services/translate.service';
import { pipe, Subject, Subscription, takeUntil } from 'rxjs';
import { LoaderService } from '../../core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit, OnDestroy {
isLoading :boolean= false;
subscription: Subscription | undefined;
onDestroy$ : Subject<void> = new Subject<void>();
constructor(private loderService:LoaderService){
}
ngOnInit(){
this.loderService.loader$.pipe(takeUntil(this.onDestroy$))
 .subscribe((flag:boolean)=>{
    this.isLoading=flag;
  })
}
ngOnDestroy(): void {
  this.onDestroy$.next();
  this.onDestroy$.complete();
}
}
