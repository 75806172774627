import { Component, HostListener, OnInit } from '@angular/core';
import { LanguageTranslateService } from '../../core/services/translate.service';
import { Language } from '../../model/header';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  languages :Language[]= [
    {name:'Deutsch',value:'de'},
    {name:"English", value:'en'},
    {name:'Spanish',value:'sp'}
  ]; 
  profileDropdownlist :any[]= [
    {name:"Change Password", value:1},
    {name:'Sign Out',value:2},
  ]; 
  selectedLang = this.languages[0].name;
  isDropdownOpen :boolean= false;
  profileDropdownOpen:boolean=false
  isAdmin:boolean=true;
  selectedTab:any;
  isLoggedInUser:boolean=false;

  constructor(private translate: LanguageTranslateService,
    private router: Router,
    private location: Location,
    private userService:UserService){
  }
  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    this.isDropdownOpen=false;
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    const currentPath = this.location.path();
    this.selectedTab=currentPath;
    this.initialSetup();
  }
  ngOnInit(){
    const currentPath = this.location.path();
    this.selectedTab=currentPath;
    this.initialSetup();
    this.userService.userDetailUpdate.subscribe((flag)=>{
      this.updateHeader();
    })
    this.userService.currentTab.subscribe((tab)=>{
      this.selectedTab=tab;
    })
  }
  setSelectedTab(tab: string) {
    this.selectedTab = tab;
  }
  toggleDropdown(){
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  toggleProfileDropdown(){
    this.profileDropdownOpen=!this.profileDropdownOpen;
  }
  selectLanguage(event:Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.translate.changeLanguage(selectElement.value);
  }
  selectItem(item:any){
    this.profileDropdownOpen = false;
    if(item.value===1){
      this.router.navigate(['/dashboard']);
    }else{
      localStorage.removeItem('token')
      this.router.navigate(['/auth']);
    }
  }
  updateHeader(){
    if(localStorage.getItem('token')){
      this.handleUser();
    }else{
      this.isLoggedInUser=false;
    }
  }
  handleUser(){
    this.isLoggedInUser=true;
    if(Number(localStorage.getItem("userRoleId"))===2 && localStorage.getItem("roleName") ==='User'){
      if(localStorage.getItem('currentPath')==undefined){
        this.selectedTab="/user-referrals/add-refer";
      }else{
        this.selectedTab=localStorage.getItem("currentPath")
      }
      this.isAdmin=false;
    }else{
      if(localStorage.getItem('currentPath')==undefined){
        this.selectedTab="/dashboard";
      }else{
        this.selectedTab=localStorage.getItem("currentPath")
      }
      this.isAdmin=true;
    }
  }
  logout(){
    this.isDropdownOpen=false;
    localStorage.removeItem('token')
    this.router.navigate(['/auth']);
    this.updateHeader();
  }
  initialSetup(){
    if(localStorage.getItem('token')){
      this.isLoggedInUser=this.location.path() !== '/change-password';
      if(Number(localStorage.getItem("userRoleId"))===2 && localStorage.getItem("roleName") ==='User'){
        this.isAdmin=false;
      }else{
        this.isAdmin=true;
      }
    }else{
      this.isLoggedInUser=false
    }
  }
  goToChangePassword(){
    this.isDropdownOpen=false;
    this.isLoggedInUser=false;
    localStorage.setItem("currentPath",this.location.path());
    this.router.navigate(['/change-password'])
  }
  getUserInitials(): string {
    return (localStorage.getItem('firstName')+' '+localStorage.getItem('lastName')).split(' ').map(name => name.charAt(0).toUpperCase()).join('');
}
}
