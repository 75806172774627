<header class="header">
    <div class="header-content">
        <div>
            <img class="logo" src="/images/nutricia-header-logo.png" alt="profile" />
        </div>
        <div *ngIf="!isAdmin && isLoggedInUser" class="user-tab d-flex justify-content-between">
            <div class="tab" [ngClass]="{ active: selectedTab === '/user-referrals/add-refer' }"
                [routerLink]="['/user-referrals/add-refer']" (click)="setSelectedTab('/user-referrals/add-refer')">
                {{ "TAB.REFER" | translate }}
            </div>
            <div class="tab" [ngClass]="{ active: selectedTab === '/user-referrals' }"
                [routerLink]="['/user-referrals']" (click)="setSelectedTab('/user-referrals')">
                {{ "TAB.REFERRALS" | translate }}
            </div>
        </div>

        <div *ngIf="isAdmin && isLoggedInUser" class="admin-tab d-flex justify-content-between">
            <div class="tab" [ngClass]="{ active: selectedTab === '/dashboard' }" [routerLink]="['/dashboard']"
                (click)="setSelectedTab('/dashboard')">
                {{ "TAB.DASHBOARD" | translate }}
            </div>
            <div class="tab" [ngClass]="{ active: selectedTab === '/referral' }" [routerLink]="['/referral']"
                (click)="setSelectedTab('/referral')">
                {{ "TAB.REFERRALS" | translate }}
            </div>
            <div class="tab" [ngClass]="{ active: selectedTab === '/users' }" [routerLink]="['/users']"
                (click)="setSelectedTab('/users')">
                {{ "TAB.USERS" | translate }}
            </div>
            <div class="tab" [ngClass]="{ active: selectedTab === '/logs' }" [routerLink]="['/logs']"
                (click)="setSelectedTab('/logs')">
                {{ "TAB.LOGS" | translate }}
            </div>
             <div class="tab" [ngClass]="{ active: selectedTab === '/master' }" [routerLink]="['/master']"
                (click)="setSelectedTab('/master')">
                {{ "TAB.MASTER" | translate }}
            </div>
        </div>
        <div class="d-flex justify-content-right">
            <!-- language custom dropdown  -->
            <!-- <div class="language-selector" (click)="toggleDropdown()">
                <span class="selected-lang">{{ selectedLang }}</span>
                <span class="selected-lang">
                    <img class="check" [src]="isDropdownOpen?'/images/drop-up.svg' :'/images/drop-down.svg'" alt="profile">
                </span>
                <div class="dropdown" *ngIf="isDropdownOpen">
                    <ul>
                        <li *ngFor="let lang of languages" [ngClass]="{'selected-lang-highlight':selectedLang==lang.name}" (click)="selectLanguage($event)">
                            <span>{{ lang.name }}</span>
                            <span *ngIf="selectedLang==lang.name"> 
                                <img  class="check" src="/images/check.svg" alt="profile">
                            </span>
                        </li>
                    </ul>
                </div>
            </div> -->
            <select name="language" class="styled-dropdown language-selector" (change)="selectLanguage($event)">
                <option [value]="lang.value" class="select-option" *ngFor="let lang of languages">
                    {{ lang.name }}
                </option>
            </select>
            <!-- <div *ngIf="isLoggedInUser">
                <button mat-icon-button class="logout" (click)="logout()">
                    <mat-icon>logout</mat-icon>
                </button>
            </div> -->
            <div *ngIf="isLoggedInUser" (click)="$event.stopPropagation()" [ngClass]="{'active-profile': isDropdownOpen}" class="user-profile d-flex justify-content-between" (click)="isDropdownOpen=!isDropdownOpen">
                <div class="profile initials">
                    {{ getUserInitials() }}
                </div>
                <div class="profile-icon">
                    <img class="drop-down-icon" src="/images/down-arrow.svg" alt="profile" />
                </div>
            </div>
            <div class="dropdown" *ngIf="isDropdownOpen">
                <ul>
                    <li (click)="goToChangePassword()">
                       {{"BUTTON.CHANGE_PASSWORD" | translate}}
                    </li>
                    <li (click)="logout()">
                        {{"BUTTON.LOGOUT" | translate}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>