import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router,private userService:UserService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const expectedRoleList = next.data['expectedRole'];
    const currentUserRole = this.userService.getRoleName();
    if(localStorage.getItem('token') && expectedRoleList.includes(currentUserRole)){
      return true;
    }
    this.router.navigate(['/auth']);
    return false;
  }
}

